export const Paths = {
    Home: { path: "/" },
    Comedians: { path: "/comedians" },
    Comedian: { path: "/comedian" },
    Jokes: { path: "/jokes" },
    Joke: { path: "/joke" },
    Videos: { path: "/videos" },
    Video: { path: "/video" },
    NotFound: { path: "/404" },
    ServerError: { path: "/500" },
    About: { path: "/about" },
    Clubs: { path: "/clubs" },
    ComedyMothership: { path: "/clubs/comedymothership" },
};
