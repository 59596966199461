import React from 'react';
import { Eye } from 'react-bootstrap-icons';

import "styles/theme.css";

export const Views = (props) => {
    const formatViews = (num) => {
        return new Intl.NumberFormat('en', {
          notation: 'compact',
          compactDisplay: 'short',
        }).format(num) + ' views';
    };

    return (
        <div className="d-flex align-items-center">
            <Eye className="me-2" color="white" />
            <span className="text-secondary">{formatViews(props.views)}</span>
        </div>
    );
};

export default Views;