import Header from 'components/header';
import React, { useEffect, useState } from 'react';
import { Container, Card, Button, Row, Col} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import { Consts } from "consts";
import Footer from 'components/footer';
import { Paths } from 'paths';
import { useParams } from "react-router-dom";
import getBackend from 'backend/backend';
import YouTubeEmbed from '../components/youtube';
import { FaTwitter, FaInstagram, FaFacebook } from 'react-icons/fa';
import "styles/theme.css";

export const selectProfileImage = (comedian) => {
  if (comedian.images.length === 0) {
    return null;
  }

  const image =  comedian.images.sort((a, b) => a.width - b.width)[comedian.images.length-1];
  let scale = 100;
  return {image: image, scale: scale};
}

const JokeCard = (props) => {
  console.log(props);
  return (
    <Row>
    <Col md={12}>
      <Card className="mb-4">
        <Card.Body>
            <YouTubeEmbed url={props.video.source_url} ts={props.joke.start} views={props.video.view_count}/>
              <Card.Text>
                {props.joke.joke}
              </Card.Text>
              <Card.Text>
                <strong>Genres:</strong> {props.joke.genres.join(", ")}
              </Card.Text>
              <Card.Text>
                <strong>Topics:</strong> {props.joke.topics.join(", ")}
              </Card.Text>
        </Card.Body>
      </Card>
    </Col>
    </Row>
  );
};

const JokesCard = (props) => {
  const [showJokes, setShowJokes] = useState(false);

  const toggleShowJokes = () => {
    setShowJokes(!showJokes);
  };

  return (
      <>
      {showJokes &&
        <>
        <h5>Jokes</h5>
        {props.video.jokes.map((joke, index) => (
          <div key={index}>
            <JokeCard video={props.video} joke={joke} />
          </div>
        ))}
        </>
      }
      <Button variant="primary" onClick={toggleShowJokes}>
        {showJokes ? 'Hide jokes' : 'Show jokes'}
      </Button>
      </>
  );
};

const VideoCard = (props) => {
    const [video, setVideo] = useState(null);

    const getVideoId = (url) => {
      const prefix = "https://www.youtube.com/watch?v=";
      let result = url.startsWith(prefix) ? url.slice(prefix.length) : url;

      return "yt" + result;
    }

    useEffect(() => {
      let canceled = false;

      const getVideo = async () => {
        const result = await getBackend().getVideo(getVideoId(props.url));
        if (canceled)
          return;

        if (result.error == null) {
          setVideo(result.response.video);
        }
      };

      getVideo();

      return async () => {
        canceled = true;
      }
    }, [props.url]);

    return (
      <Row>
      <Col md={12}>
        <Card className="mb-4">
          {video &&
            <Card.Header>{video.title}</Card.Header>
          }
          <Card.Body>
            <Row>
              <Col md={6}>
                <YouTubeEmbed url={props.url} ts="0" views={video? video.view_count : 0}/>
              </Col>
              <Col md={6}>
                {video &&
                  <>
                  <Card.Text>
                    <strong>Genres:</strong> {video.genres.join(", ")}
                  </Card.Text>
                  <Card.Text>
                    <strong>Topics:</strong> {video.topics.join(", ")}
                  </Card.Text>
                  <Card.Text>
                    <strong>Performance:</strong> {video.performance_types.join(", ")}
                  </Card.Text>
                  {video.jokes.length > 0 &&
                    <JokesCard video={video} />
                  }
                  </>
                }
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      </Row>
    )
}

const SocialLink = (props) => {
  return (
    <>
      {props.link.startsWith("https://www.facebook.com/") &&
        <a href={props.link} target="_blank" rel="noopener noreferrer"><FaFacebook size={24} /></a>
      }
      {props.link.startsWith("https://www.instagram.com/") &&
        <a href={props.link} target="_blank" rel="noopener noreferrer"><FaInstagram size={24} /></a>
      }
      {props.link.startsWith("https://www.twitter.com/") &&
        <a href={props.link} target="_blank" rel="noopener noreferrer"><FaTwitter size={24} /></a>
      }
      {props.link.startsWith("https://www.x.com/") &&
        <a href={props.link} target="_blank" rel="noopener noreferrer"><FaTwitter size={24} /></a>
      }
    </>
  )
}

const ComedianCard = (props) => {
  const comedian = props.comedian;
  const [showFullBio, setShowFullBio] = useState(false);
  const bioLimit = 600;

  const image = selectProfileImage(comedian);

  const toggleShowFullBio = () => {
    setShowFullBio(!showFullBio);
  };

  const getDisplayBio = (text) => {
    if (showFullBio) {
      return text;
    } else {
      return text.length > bioLimit ? text.substring(0, bioLimit) + '...' : text;
    }
  };

  let social_links = [];
  let personal_links = [];
  for (let i = 0; i < comedian.social_links.length; i++) {
    const link = comedian.social_links[i];

    if (link.startsWith("https://www.facebook.com/") ||
        link.startsWith("https://www.instagram.com/") ||
        link.startsWith("https://www.twitter.com/") ||
        link.startsWith("https://www.x.com/")) {
      social_links.push(link);
    } else {
      personal_links.push(link);
    }
  }

  return (
    <Container className="mt-4">
      <Card>
          <Card.Header>{comedian.name}</Card.Header>
          <Card.Body>
            <Row>
              <Col md={4}>
                {image &&
                  <Card.Img variant="top" src={image.image.url} style={{ width: '' + image.scale + '%', height: 'auto' }} lt="profile image" />
                }
              </Col>
              <Col md={8}>
                {comedian.burn && !comedian.burn.includes("?") &&
                  <Card.Text><strong>Born:</strong> {comedian.burn}</Card.Text>
                }
                {comedian.current_city &&
                  <Card.Text><strong>City:</strong> {comedian.current_city}, {comedian.current_state}, {comedian.current_country}</Card.Text>
                }
                {comedian.genres.length > 0 &&
                  <Card.Text><strong>Genres:</strong> {comedian.genres.join(", ")}</Card.Text>
                }
                {comedian.topics.length > 0 &&
                  <Card.Text><strong>Topics:</strong> {comedian.topics.join(", ")}</Card.Text>
                }
                {comedian.awards.length > 0 &&
                  <Card.Text><strong>Awards:</strong> {comedian.awards.join(", ")}</Card.Text>
                }
                {comedian.hobbies.length > 0 &&
                  <Card.Text><strong>Hobbies:</strong> {comedian.hobbies.join(", ")}</Card.Text>
                }
                {social_links.length > 0 &&
                  <>
                    <Card.Text><strong>Profiles: </strong>
                    {social_links.map((link, index) => (
                        <SocialLink link={link}/>
                    ))}
                    </Card.Text>
                  </>
                }
                {personal_links.length > 0 &&
                  <>
                    <Card.Text><strong>Website: </strong>
                    {personal_links.map((link, index) => (
                        <a href={link} target="_blank" rel="noopener noreferrer">{link}</a>
                    ))}
                    </Card.Text>
                  </>
                }
                <Card.Text>
                  {getDisplayBio(comedian.bio)}
                </Card.Text>
                {comedian.bio.length > bioLimit && (
                  <Button variant="primary" onClick={toggleShowFullBio}>
                    {showFullBio ? 'Show less' : 'Show more'}
                  </Button>
                )}
              </Col>
            </Row>

          </Card.Body>
          <Card.Body>
            <h5>Videos</h5>
            {comedian.videos.map((video, index) => (
              <div key={index}>
                <VideoCard url={video} />
              </div>
            ))}
          </Card.Body>
          {/* TODO:
          <Card.Body>
            <h5>Buy Links</h5>
            {comedian.buy_links.map((link, index) => (
              <div key={index}>
                <a href={link} target="_blank" rel="noopener noreferrer">{link}</a>
              </div>
            ))}
          </Card.Body>
          */}
      </Card>
    </Container>
  );
}

const Comedian = ({match}) => {
    const [person, setPerson] = useState(null);

    let { id } = useParams(); 

    useEffect(() => {
      let canceled = false;

      const getComedian = async () => {
        const result = await getBackend().getComedian(id);
        if (canceled)
          return;
  
        if (result.error == null) {
          setPerson(result.response.comedian);
        } else {
            window.location.href = Paths.NotFound.path;
        }
      };

      getComedian(id);

      return async () => {
        canceled = true;
      }
    }, [id]);

    return (
        <div>
        <Helmet>
            <title>{person? person.name: ''} | {Consts.ProjectName} - Discover Stand-Up Comedy, Comedian, and Jokes</title>
            <meta name="description" content={`Explore ${person? person.name : ''} stand-up comedy profile on ${Consts.ProjectName}. Discover unique comedy styles, jokes, show videos, and punchlines.`} />
            <meta name="keywords" content={`${person? person.name: ''}, comedian, stand-up comedy, punchlines, jokes, specials`} />
        </Helmet>
        <Header/>

        {person &&
          <ComedianCard comedian={person}/>
        }
        
        <Footer/>
      </div>
    );
}

export default Comedian;