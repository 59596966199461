
import React from "react";
import { Col, Row, Button, Container } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import { Paths } from "paths";
import Footer from "components/footer";
import Header from "components/header";
import {Helmet} from "react-helmet";
import { Consts } from "consts";

import "styles/theme.css";

const PageNotFound = () => {

  return (
    <>
      <Helmet>
        <title>{Consts.ProjectName} Page Not Found</title>
        <meta name="description" content="page not found" />
      </Helmet>
      <Header/>
      <section className="vh-100 d-flex align-items-center justify-content-center">
        <Container>
          <Row>
            <Col xs={12} className="text-center d-flex align-items-center justify-content-center">
              <div>
                <p className="lead my-4 message-text-color">
                  Oops! This page is playing hide and seek.
                </p>
                <Button as={Link} className="d-inline-flex align-items-center justify-content-center mb-4" to={Paths.Home.path}>
                  Teleport home
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer/>
    </>
  );
};

export default PageNotFound;