
import React from "react";
import {Navbar, Nav, Image} from 'react-bootstrap';

import "styles/theme.css";
import Logo from "assets/punchhub.png"

import { NavLink } from 'react-router-dom';

const Header = (props) => {

  const activeStyle = {
    border: '2px solid #BE5D8F',
    borderRadius: '9999px',
    color: '#BE5D8F',
    padding: '5px 10px',
    boxSizing: 'border-box',
    transition: 'all 0.3s ease',
  };

  return (
    <>
        <header className="header mt-auto py-3">
        <Navbar expand="lg" className="ml-4">
            <Navbar.Brand as={NavLink} to="/">
            <Image
                src={Logo}
                alt="Logo"
                style={{
                width: '60px',
                height: 'auto',
                filter: 'drop-shadow(0 0 10px rgba(255, 255, 255, 0.8))',
                }}
            />
            <span className="ml-2 header-text">PunchHub.ai</span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
                <Nav.Link as={NavLink} to="/comedians" className="header-text" style={({ isActive }) => isActive ? activeStyle: {}}>Comedians</Nav.Link>
                <Nav.Link as={NavLink} to="/jokes" className="header-text" style={({ isActive }) => isActive ? activeStyle: {}}>Jokes</Nav.Link>
                <Nav.Link as={NavLink} to="/clubs" className="header-text" style={({ isActive }) => isActive ? activeStyle: {}}>Clubs</Nav.Link>
                {/* TODO: <Nav.Link href="/videos">Videos</Nav.Link> */}
                <Nav.Link as={NavLink} to="/about" className="header-text" style={({ isActive }) => isActive ? activeStyle: {}}>About</Nav.Link>
            </Nav>
            </Navbar.Collapse>
        </Navbar>
        </header>
    </>
)
}

export default Header;