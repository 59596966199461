import HttpClient from './http';
import devmode from './env';

class Backend {
    constructor() {
        this.host_url = (devmode()) ? "http://127.0.0.1:8080" : window.location.origin;
        this.client = new HttpClient(this.host_url);

    }

    async health() {
        return await this.client.get('/api/health', {});
    }

    async getComedian(id) {
        return await this.client.get('/api/comedian/' + id, {});
    }

    async searchComedian(query) {
        return await this.client.post('/api/comedian/search', {query: query}, {});
    }

    async getVideo(id) {
        return await this.client.get('/api/video/' + id, {});
    }

    async searchVideo(query, params) {
        return await this.client.post('/api/video/search', {query: query, params: params}, {});
    }

    async getJoke(id) {
        return await this.client.get('/api/joke/' + id, {});
    }

    async searchJoke(query, params) {
        return await this.client.post('/api/joke/search', {query: query, params: params}, {});
    }
}

var backend = null;

function getBackend() {
    if (backend != null)
        return backend;

    backend = new Backend();
    return backend;
}

export default getBackend