import React from 'react';
import { Helmet } from 'react-helmet';
import { Consts } from 'consts';

import "styles/theme.css";
import Header from 'components/header';
import Footer from 'components/footer';
import { Container } from 'react-bootstrap';
import Frame from "react-frame-component";

const ComedyMothership = () => {

    const customHtml = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Comedy Mothership Shows</title>
        <script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>
        <link rel="stylesheet" type="text/css" href="https://cdn.datatables.net/1.10.24/css/jquery.dataTables.css">
        <script type="text/javascript" charset="utf8" src="https://cdn.datatables.net/1.10.24/js/jquery.dataTables.js"></script>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css">
        <style>
            body { 
                font-family: Arial, sans-serif; 
                padding: 20px;
                background-color: #1ccfd9; /* New background color */
            }
            table { 
                width: 100%; 
                border-collapse: collapse; 
                background-color: white; /* White background for table */
                box-shadow: 0 0 10px rgba(0,0,0,0.1); /* Subtle shadow for the table */
            }
            th, td { padding: 8px; text-align: left; border-bottom: 1px solid #ddd; }
            th { background-color: #f2f2f2; }
            .time { font-size: 0.8em; color: #666; }
            .status-bullet { display: inline-block; width: 10px; height: 10px; border-radius: 50%; margin-right: 5px; }
            .status-available { background-color: green; }
            .status-sold-out { background-color: red; }
            .column-filter { width: 100%; box-sizing: border-box; margin-top: 5px; }
            .dataTables_filter { display: none; }
            #searchIcon { 
                position: absolute; 
                right: 20px; 
                top: 20px; 
                font-size: 24px; 
                cursor: pointer;
            }
            #searchInput {
                display: none;
                position: absolute;
                right: 50px;
                top: 20px;
                padding: 5px;
            }
            .title-container {
                display: flex;
                align-items: center;
                gap: 10px;
            }
            .title-link {
                font-size: 24px;
                color: #000000; /* Changed to black */
                text-decoration: none;
            }
        </style>
    </head>
    <body>
        <div class="title-container">
            <h1>Comedy Mothership Shows</h1>
            <a href="http://comedymothership.com/shows" target="_blank" class="title-link" title="Visit Comedy Mothership">
                <i class="fas fa-external-link-alt"></i>
            </a>
        </div>
        <i id="searchIcon" class="fas fa-search"></i>
        <input type="text" id="searchInput" placeholder="Search...">
        <table id="showsTable">
            <thead>
                <tr>
                    <th>Date <br><select id="monthFilter" class="column-filter"></select></th>
                    <th>Show</th>
                    <th>Ticket Status <br><select id="statusFilter" class="column-filter"></select></th>
                    <th>Comedian Profile</th>
                </tr>
            </thead>
            <tbody>
    
                <tr>
                    <td data-month="Sep">Sep 15 (Su)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-0ojq"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 15 (Su)<br><span class="time">08:00PM</span></td>
                    <td>Sam Tallent Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/sam-tallent-headlines-fat-man-z56"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/sam-tallent" target="_blank">Sam Tallent</a></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 16 (Mo)<br><span class="time">07:00PM</span></td>
                    <td>Mothership Open Mic and Crew Show</td>
                    <td><a href="https://www.showclix.com/event/open-mic-6em"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 16 (Mo)<br><span class="time">08:00PM</span></td>
                    <td>KILL TONY</td>
                    <td><a href="https://www.showclix.com/event/kill-tony-o8l-mothership-s4e"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 17 (Tu)<br><span class="time">07:00PM</span></td>
                    <td>Duncan Trussell and Friends</td>
                    <td><a href="https://www.showclix.com/event/duncan-trussell-and-friends-njf"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/duncan-trussell" target="_blank">Duncan Trussell</a></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 17 (Tu)<br><span class="time">07:30PM</span></td>
                    <td>Bottom of the Barrel</td>
                    <td><a href="https://www.showclix.com/event/bottom-of-the-barrel-iz2"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 17 (Tu)<br><span class="time">10:00PM</span></td>
                    <td>The Best of Comedy Mothership</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-ygf"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 17 (Tu)<br><span class="time">10:30PM</span></td>
                    <td>Bottom of the Barrel</td>
                    <td><a href="https://www.showclix.com/event/bottom-of-the-barrel-0si"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 18 (We)<br><span class="time">07:00PM</span></td>
                    <td>Tony Hinchcliffe and His Current Friends</td>
                    <td><a href="#"><span class="status-bullet status-sold-out"></span></a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 18 (We)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-fi8"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 18 (We)<br><span class="time">10:00PM</span></td>
                    <td>The Best of Comedy Mothership</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-y1f0"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 18 (We)<br><span class="time">10:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-u5"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 19 (Th)<br><span class="time">07:00PM</span></td>
                    <td>Ari Matti and Friends</td>
                    <td><a href="https://www.showclix.com/event/ari-matti-and-friends-gzy"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/ari-matti" target="_blank">Ari Matti</a></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 19 (Th)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-ump"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 19 (Th)<br><span class="time">10:00PM</span></td>
                    <td>Brian Holtzman and Friends</td>
                    <td><a href="https://www.showclix.com/event/brian-holtzman-and-friends-rg"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/brian-holtzman" target="_blank">Brian Holtzman</a></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 19 (Th)<br><span class="time">10:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-bxb"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 20 (Fr)<br><span class="time">07:00PM</span></td>
                    <td>Dusty Slay Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/dusty-slay-headlines-fat-man"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/dusty-slay" target="_blank">Dusty Slay</a></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 20 (Fr)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-n5r"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 20 (Fr)<br><span class="time">10:00PM</span></td>
                    <td>Dusty Slay Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/dusty-slay-headlines-fat-man-qlf"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/dusty-slay" target="_blank">Dusty Slay</a></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 20 (Fr)<br><span class="time">10:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-bz"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 21 (Sa)<br><span class="time">07:00PM</span></td>
                    <td>Dusty Slay Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/dusty-slay-headlines-fat-man-glc"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/dusty-slay" target="_blank">Dusty Slay</a></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 21 (Sa)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-4dt"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 21 (Sa)<br><span class="time">10:00PM</span></td>
                    <td>Dusty Slay Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/dusty-slay-headlines-fat-man-lyrz"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/dusty-slay" target="_blank">Dusty Slay</a></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 21 (Sa)<br><span class="time">10:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-ofhv"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 22 (Su)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-kzc"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 22 (Su)<br><span class="time">08:00PM</span></td>
                    <td>Dusty Slay Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/dusty-slay-headlines-fat-man-xoiey"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/dusty-slay" target="_blank">Dusty Slay</a></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 23 (Mo)<br><span class="time">07:00PM</span></td>
                    <td>Mothership Open Mic and Crew Show</td>
                    <td><a href="https://www.showclix.com/event/open-mic-txb"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 23 (Mo)<br><span class="time">08:00PM</span></td>
                    <td>KILL TONY</td>
                    <td><a href="https://www.showclix.com/event/kill-tony-o8l-mothership-stle"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 24 (Tu)<br><span class="time">07:30PM</span></td>
                    <td>Bottom of the Barrel</td>
                    <td><a href="https://www.showclix.com/event/bottom-of-the-barrel-rpf"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 24 (Tu)<br><span class="time">10:00PM</span></td>
                    <td>The Best of Comedy Mothership</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-pd2"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 24 (Tu)<br><span class="time">10:30PM</span></td>
                    <td>Bottom of the Barrel</td>
                    <td><a href="https://www.showclix.com/event/bottom-of-the-barrel-hs5"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 25 (We)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-5kg"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 25 (We)<br><span class="time">10:00PM</span></td>
                    <td>The Best of Comedy Mothership</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-nbl"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 25 (We)<br><span class="time">10:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-eexr"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 26 (Th)<br><span class="time">07:00PM</span></td>
                    <td>Harland Williams Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/harland-williams-headlines-fat-man-usew"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/harland-williams" target="_blank">Harland Williams</a></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 26 (Th)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-b7a"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 26 (Th)<br><span class="time">10:00PM</span></td>
                    <td>Harland Williams Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/harland-williams-headlines-fat-man-usew6r907cc"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/harland-williams" target="_blank">Harland Williams</a></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 26 (Th)<br><span class="time">10:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-mlky"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 27 (Fr)<br><span class="time">07:00PM</span></td>
                    <td>Harland Williams Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/harland-williams-headlines-fat-man-wdskybhlaf"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/harland-williams" target="_blank">Harland Williams</a></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 27 (Fr)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-ib0"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 27 (Fr)<br><span class="time">10:00PM</span></td>
                    <td>Harland Williams Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/harland-williams-headlines-fat-man-tsx98"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/harland-williams" target="_blank">Harland Williams</a></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 27 (Fr)<br><span class="time">10:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-hg"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 28 (Sa)<br><span class="time">07:00PM</span></td>
                    <td>Harland Williams Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/harland-williams-headlines-fat-man-yh5j"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/harland-williams" target="_blank">Harland Williams</a></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 28 (Sa)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-wcsw"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 28 (Sa)<br><span class="time">10:00PM</span></td>
                    <td>Harland Williams Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/harland-williams-headlines-fat-man-uso"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/harland-williams" target="_blank">Harland Williams</a></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 28 (Sa)<br><span class="time">10:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-mrdv"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 29 (Su)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-ff3"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 29 (Su)<br><span class="time">08:00PM</span></td>
                    <td>Harland Williams Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/harland-williams-headlines-fat-man-yg"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/harland-williams" target="_blank">Harland Williams</a></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 30 (Mo)<br><span class="time">07:00PM</span></td>
                    <td>Mothership Open Mic and Crew Show</td>
                    <td><a href="https://www.showclix.com/event/open-mic-qmrs"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Sep">Sep 30 (Mo)<br><span class="time">08:00PM</span></td>
                    <td>Roast Battle League</td>
                    <td><a href="https://www.showclix.com/event/roast-battle-league-7vn"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 01 (Tu)<br><span class="time">07:30PM</span></td>
                    <td>Bottom of the Barrel</td>
                    <td><a href="https://www.showclix.com/event/bottom-of-the-barrel-qqz"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 01 (Tu)<br><span class="time">10:30PM</span></td>
                    <td>Bottom of the Barrel</td>
                    <td><a href="https://www.showclix.com/event/bottom-of-the-barrel-xejp"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 02 (We)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-ef7"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 02 (We)<br><span class="time">10:00PM</span></td>
                    <td>The Best of Comedy Mothership</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-cw8"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 02 (We)<br><span class="time">10:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-g1ef"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 03 (Th)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-obr"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 03 (Th)<br><span class="time">10:00PM</span></td>
                    <td>The Best of Comedy Mothership</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-73x"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 03 (Th)<br><span class="time">10:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-4u"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 04 (Fr)<br><span class="time">07:00PM</span></td>
                    <td>Russell Peters Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/russell-peters-headlines-vcv"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/russell-peters" target="_blank">Russell Peters</a></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 04 (Fr)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-mzu"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 04 (Fr)<br><span class="time">10:00PM</span></td>
                    <td>Russell Peters Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/russell-peters-headlines-2fcq"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/russell-peters" target="_blank">Russell Peters</a></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 04 (Fr)<br><span class="time">10:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-w9q"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 05 (Sa)<br><span class="time">07:00PM</span></td>
                    <td>Russell Peters Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/russell-peters-headlines-c7a1"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/russell-peters" target="_blank">Russell Peters</a></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 05 (Sa)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-ie9"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 05 (Sa)<br><span class="time">10:00PM</span></td>
                    <td>Russell Peters Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/russell-peters-headlines-x3nx"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/russell-peters" target="_blank">Russell Peters</a></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 05 (Sa)<br><span class="time">10:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-o2f"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 06 (Su)<br><span class="time">05:00PM</span></td>
                    <td>Roast Battle League</td>
                    <td><a href="https://www.showclix.com/event/roast-battle-league-bm"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 06 (Su)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-9m6"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 06 (Su)<br><span class="time">08:00PM</span></td>
                    <td>Roast Battle League</td>
                    <td><a href="https://www.showclix.com/event/roast-battle-league-ffet"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 07 (Mo)<br><span class="time">06:00PM</span></td>
                    <td>KILL TONY</td>
                    <td><a href="https://www.showclix.com/event/kill-tony-o8l-mothership-stlehgeoehj"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 07 (Mo)<br><span class="time">07:00PM</span></td>
                    <td>Mothership Open Mic and Crew Show</td>
                    <td><a href="https://www.showclix.com/event/open-mic-0xb"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 07 (Mo)<br><span class="time">10:00PM</span></td>
                    <td>KILL TONY</td>
                    <td><a href="https://www.showclix.com/event/kill-tony-o8l-mothership-stlehgeoehje9wbwmj"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 08 (Tu)<br><span class="time">07:30PM</span></td>
                    <td>Bottom of the Barrel</td>
                    <td><a href="https://www.showclix.com/event/bottom-of-the-barrel-ng"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 08 (Tu)<br><span class="time">10:00PM</span></td>
                    <td>The Best of Comedy Mothership</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-1k5f"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 08 (Tu)<br><span class="time">10:30PM</span></td>
                    <td>Bottom of the Barrel</td>
                    <td><a href="https://www.showclix.com/event/bottom-of-the-barrel-uwh"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 09 (We)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-yitz"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 09 (We)<br><span class="time">10:00PM</span></td>
                    <td>The Best of Comedy Mothership</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-gjnb"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 09 (We)<br><span class="time">10:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-hnj"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 10 (Th)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-dx"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 10 (Th)<br><span class="time">10:00PM</span></td>
                    <td>The Best of Comedy Mothership</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-uho"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 10 (Th)<br><span class="time">10:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-c6b"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 11 (Fr)<br><span class="time">07:00PM</span></td>
                    <td>Pauly Shore Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/pauly-shore-xnc"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/pauly-shore" target="_blank">Pauly Shore</a></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 11 (Fr)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-pes"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 11 (Fr)<br><span class="time">10:00PM</span></td>
                    <td>Pauly Shore Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/pauly-shore-el3"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/pauly-shore" target="_blank">Pauly Shore</a></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 11 (Fr)<br><span class="time">10:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-pfw"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 12 (Sa)<br><span class="time">07:00PM</span></td>
                    <td>Pauly Shore Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/pauly-shore-2d"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/pauly-shore" target="_blank">Pauly Shore</a></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 12 (Sa)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-ctzt"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 12 (Sa)<br><span class="time">10:00PM</span></td>
                    <td>Pauly Shore Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/pauly-shore-wr"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/pauly-shore" target="_blank">Pauly Shore</a></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 12 (Sa)<br><span class="time">10:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-vh5"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 13 (Su)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-rjs"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 13 (Su)<br><span class="time">08:00PM</span></td>
                    <td>Pauly Shore Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/pauly-shore-dc"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/pauly-shore" target="_blank">Pauly Shore</a></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 14 (Mo)<br><span class="time">07:00PM</span></td>
                    <td>Mothership Open Mic and Crew Show</td>
                    <td><a href="https://www.showclix.com/event/open-mic-fjph"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 14 (Mo)<br><span class="time">08:00PM</span></td>
                    <td>Kam Patterson and Friends</td>
                    <td><a href="https://www.showclix.com/event/kam-patterson-and-qa"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/kam-patterson" target="_blank">Kam Patterson</a></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 15 (Tu)<br><span class="time">07:30PM</span></td>
                    <td>Bottom of the Barrel</td>
                    <td><a href="https://www.showclix.com/event/bottom-of-the-barrel-vla"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 15 (Tu)<br><span class="time">10:00PM</span></td>
                    <td>The Best of Comedy Mothership</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-byi"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 15 (Tu)<br><span class="time">10:30PM</span></td>
                    <td>Bottom of the Barrel</td>
                    <td><a href="https://www.showclix.com/event/bottom-of-the-barrel-hln"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 16 (We)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-1m6"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 16 (We)<br><span class="time">10:00PM</span></td>
                    <td>The Best of Comedy Mothership</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-ye"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 16 (We)<br><span class="time">10:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-1rlx"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 17 (Th)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-gh1"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 17 (Th)<br><span class="time">10:00PM</span></td>
                    <td>Brian Holtzman and Friends</td>
                    <td><a href="https://www.showclix.com/event/brian-holtzman-and-friends-eg4"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/brian-holtzman" target="_blank">Brian Holtzman</a></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 17 (Th)<br><span class="time">10:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-pm"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 18 (Fr)<br><span class="time">07:00PM</span></td>
                    <td>Adrienne Iapalucci Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/adrienne-iapalucci-headlines-fat-man-8f3"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/adrienne-iapalucci" target="_blank">Adrienne Iapalucci</a></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 18 (Fr)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-lzqn"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 18 (Fr)<br><span class="time">10:00PM</span></td>
                    <td>Adrienne Iapalucci Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/adrienne-iapalucci-headlines-fat-man-kxm"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/adrienne-iapalucci" target="_blank">Adrienne Iapalucci</a></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 18 (Fr)<br><span class="time">10:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-4pv"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 19 (Sa)<br><span class="time">07:00PM</span></td>
                    <td>Adrienne Iapalucci Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/adrienne-iapalucci-headlines-fat-man-ls"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/adrienne-iapalucci" target="_blank">Adrienne Iapalucci</a></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 19 (Sa)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-clv"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 19 (Sa)<br><span class="time">10:00PM</span></td>
                    <td>Adrienne Iapalucci Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/adrienne-iapalucci-headlines-fat-man-l54"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/adrienne-iapalucci" target="_blank">Adrienne Iapalucci</a></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 19 (Sa)<br><span class="time">10:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-qfy"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 20 (Su)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-80"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 20 (Su)<br><span class="time">08:00PM</span></td>
                    <td>Adrienne Iapalucci Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/adrienne-iapalucci-headlines-fat-man-qlg"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/adrienne-iapalucci" target="_blank">Adrienne Iapalucci</a></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 21 (Mo)<br><span class="time">07:00PM</span></td>
                    <td>Mothership Open Mic and Crew Show</td>
                    <td><a href="https://www.showclix.com/event/open-mic-ltz5"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 21 (Mo)<br><span class="time">08:00PM</span></td>
                    <td>Casey Rocket and Friends</td>
                    <td><a href="https://www.showclix.com/event/casey-rocket-and-friends8b94oz3"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/casey-rocket" target="_blank">Casey Rocket</a></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 22 (Tu)<br><span class="time">07:30PM</span></td>
                    <td>Bottom of the Barrel</td>
                    <td><a href="https://www.showclix.com/event/bottom-of-the-barrel-sfoh"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 22 (Tu)<br><span class="time">10:00PM</span></td>
                    <td>The Best of Comedy Mothership</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-llq"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 22 (Tu)<br><span class="time">10:30PM</span></td>
                    <td>Bottom of the Barrel</td>
                    <td><a href="https://www.showclix.com/event/bottom-of-the-barrel-07"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 23 (We)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-x6tjy"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 23 (We)<br><span class="time">10:00PM</span></td>
                    <td>The Best of Comedy Mothership</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-ad"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 23 (We)<br><span class="time">10:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-kw"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 24 (Th)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-yc"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 24 (Th)<br><span class="time">10:00PM</span></td>
                    <td>Brian Holtzman and Friends</td>
                    <td><a href="https://www.showclix.com/event/brian-holtzman-and-friends-vr3"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/brian-holtzman" target="_blank">Brian Holtzman</a></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 24 (Th)<br><span class="time">10:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-cbjt"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 25 (Fr)<br><span class="time">07:00PM</span></td>
                    <td>Donnell Rawlings Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/donnell-rawlings-headlines-fat-man-vcf"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/donnell-rawlings" target="_blank">Donnell Rawlings</a></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 25 (Fr)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-jtp"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 25 (Fr)<br><span class="time">10:00PM</span></td>
                    <td>Donnell Rawlings Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/donnell-rawlings-headlines-fat-man-nq"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/donnell-rawlings" target="_blank">Donnell Rawlings</a></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 25 (Fr)<br><span class="time">10:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-srw"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 26 (Sa)<br><span class="time">07:00PM</span></td>
                    <td>Donnell Rawlings Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/donnell-rawlings-headlines-fat-man-vcz"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/donnell-rawlings" target="_blank">Donnell Rawlings</a></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 26 (Sa)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-ljlc"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 26 (Sa)<br><span class="time">10:00PM</span></td>
                    <td>Donnell Rawlings Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/donnell-rawlings-headlines-fat-man-4g7"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/donnell-rawlings" target="_blank">Donnell Rawlings</a></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 26 (Sa)<br><span class="time">10:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-uuf"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 27 (Su)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-e7j"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 27 (Su)<br><span class="time">08:00PM</span></td>
                    <td>Donnell Rawlings Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/donnell-rawlings-headlines-fat-man-fjd"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/donnell-rawlings" target="_blank">Donnell Rawlings</a></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 28 (Mo)<br><span class="time">07:00PM</span></td>
                    <td>Mothership Open Mic and Crew Show</td>
                    <td><a href="https://www.showclix.com/event/open-mic-ryq"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 28 (Mo)<br><span class="time">08:00PM</span></td>
                    <td>KILL TONY</td>
                    <td><a href="https://www.showclix.com/event/kill-tony-o8l-mothership-stlehgeoehje9wbwmjyqikayf"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 29 (Tu)<br><span class="time">07:30PM</span></td>
                    <td>Bottom of the Barrel</td>
                    <td><a href="https://www.showclix.com/event/bottom-of-the-barrel-poy"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 29 (Tu)<br><span class="time">10:00PM</span></td>
                    <td>The Best of Comedy Mothership</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-pgu"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 29 (Tu)<br><span class="time">10:30PM</span></td>
                    <td>Bottom of the Barrel</td>
                    <td><a href="https://www.showclix.com/event/bottom-of-the-barrel-mfu"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 30 (We)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-mtu"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 30 (We)<br><span class="time">10:00PM</span></td>
                    <td>The Best of Comedy Mothership</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-md"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 30 (We)<br><span class="time">10:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-xwi"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 31 (Th)<br><span class="time">07:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-qm"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 31 (Th)<br><span class="time">10:00PM</span></td>
                    <td>Brian Holtzman and Friends</td>
                    <td><a href="https://www.showclix.com/event/brian-holtzman-and-friends-afb"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/brian-holtzman" target="_blank">Brian Holtzman</a></td>
                </tr>
        
                <tr>
                    <td data-month="Oct">Oct 31 (Th)<br><span class="time">10:30PM</span></td>
                    <td>The Comedy Mothership Showcase</td>
                    <td><a href="https://www.showclix.com/event/the-comedy-showcase-seo"><span class="status-bullet status-available"></span>Available</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Nov">Nov 01 (Fr)<br><span class="time">07:00PM</span></td>
                    <td>Ian Bagg Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/ian-bagg-headlines-fat-man-vf3"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/ian-bagg" target="_blank">Ian Bagg</a></td>
                </tr>
        
                <tr>
                    <td data-month="Nov">Nov 01 (Fr)<br><span class="time">10:00PM</span></td>
                    <td>Ian Bagg Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/ian-bagg-headlines-fat-man-qxd"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/ian-bagg" target="_blank">Ian Bagg</a></td>
                </tr>
        
                <tr>
                    <td data-month="Nov">Nov 02 (Sa)<br><span class="time">07:00PM</span></td>
                    <td>Ian Bagg Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/ian-bagg-headlines-fat-man-dmy"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/ian-bagg" target="_blank">Ian Bagg</a></td>
                </tr>
        
                <tr>
                    <td data-month="Nov">Nov 02 (Sa)<br><span class="time">10:00PM</span></td>
                    <td>Ian Bagg Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/ian-bagg-headlines-fat-man-kieh"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/ian-bagg" target="_blank">Ian Bagg</a></td>
                </tr>
        
                <tr>
                    <td data-month="Nov">Nov 03 (Su)<br><span class="time">08:00PM</span></td>
                    <td>Ian Bagg Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/ian-bagg-headlines-fat-man-w7oa"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/ian-bagg" target="_blank">Ian Bagg</a></td>
                </tr>
        
                <tr>
                    <td data-month="Nov">Nov 04 (Mo)<br><span class="time">08:00PM</span></td>
                    <td>KILL TONY</td>
                    <td><a href="https://www.showclix.com/event/kill-tony-o8l-mothership-stlehgeoehje9wbwmjyqikayfvltn4kf"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Nov">Nov 08 (Fr)<br><span class="time">07:00PM</span></td>
                    <td>Big Jay Oakerson Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/big-jay-oakerson-headlines-fat-man"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/big-jay-oakerson" target="_blank">Big Jay Oakerson</a></td>
                </tr>
        
                <tr>
                    <td data-month="Nov">Nov 08 (Fr)<br><span class="time">10:00PM</span></td>
                    <td>Big Jay Oakerson Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/big-jay-oakerson-headlines-fat-oyr"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/big-jay-oakerson" target="_blank">Big Jay Oakerson</a></td>
                </tr>
        
                <tr>
                    <td data-month="Nov">Nov 09 (Sa)<br><span class="time">07:00PM</span></td>
                    <td>Big Jay Oakerson Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/big-jay-oakerson-headlines-fat-bm"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/big-jay-oakerson" target="_blank">Big Jay Oakerson</a></td>
                </tr>
        
                <tr>
                    <td data-month="Nov">Nov 09 (Sa)<br><span class="time">10:00PM</span></td>
                    <td>Big Jay Oakerson Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/big-jay-oakerson-headlines-fat-bmvooanxs"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/big-jay-oakerson" target="_blank">Big Jay Oakerson</a></td>
                </tr>
        
                <tr>
                    <td data-month="Nov">Nov 10 (Su)<br><span class="time">08:00PM</span></td>
                    <td>Big Jay Oakerson Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/big-jay-oakerson-headlines-fat-8eut"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/big-jay-oakerson" target="_blank">Big Jay Oakerson</a></td>
                </tr>
        
                <tr>
                    <td data-month="Nov">Nov 11 (Mo)<br><span class="time">08:00PM</span></td>
                    <td>KILL TONY</td>
                    <td><a href="https://www.showclix.com/event/kill-tony-o8l-mothership-stlehgeoehje9wbwmjyqikayfaig9sql"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Nov">Nov 15 (Fr)<br><span class="time">07:00PM</span></td>
                    <td>Tim Dillon Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/tim-dillon-headlines-fat-man-kn2k"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/tim-dillon" target="_blank">Tim Dillon</a></td>
                </tr>
        
                <tr>
                    <td data-month="Nov">Nov 15 (Fr)<br><span class="time">10:00PM</span></td>
                    <td>Tim Dillon Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/tim-dillon-headlines-fat-man-fgggu"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/tim-dillon" target="_blank">Tim Dillon</a></td>
                </tr>
        
                <tr>
                    <td data-month="Nov">Nov 16 (Sa)<br><span class="time">07:00PM</span></td>
                    <td>Tim Dillon Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/tim-dillon-headlines-fat-man-3ftr"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/tim-dillon" target="_blank">Tim Dillon</a></td>
                </tr>
        
                <tr>
                    <td data-month="Nov">Nov 16 (Sa)<br><span class="time">10:00PM</span></td>
                    <td>Tim Dillon Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/tim-dillon-headlines-fat-man-ahi"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/tim-dillon" target="_blank">Tim Dillon</a></td>
                </tr>
        
                <tr>
                    <td data-month="Nov">Nov 17 (Su)<br><span class="time">08:00PM</span></td>
                    <td>Tim Dillon Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/tim-dillon-headlines-fat-man-46x"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td><a href="https://punchhub.ai/comedian/tim-dillon" target="_blank">Tim Dillon</a></td>
                </tr>
        
                <tr>
                    <td data-month="Nov">Nov 18 (Mo)<br><span class="time">08:00PM</span></td>
                    <td>KILL TONY</td>
                    <td><a href="https://www.showclix.com/event/kill-tony-o8l-mothership-stlehgeoehje9wbwmjyqikayfk5vrgyf"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Nov">Nov 22 (Fr)<br><span class="time">07:00PM</span></td>
                    <td>Kyle Kinane Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/kyle-kinane-headlines-fat-man"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/kyle-kinane" target="_blank">Kyle Kinane</a></td>
                </tr>
        
                <tr>
                    <td data-month="Nov">Nov 22 (Fr)<br><span class="time">10:00PM</span></td>
                    <td>Kyle Kinane Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/kyle-kinane-headlines-fat-man-is"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/kyle-kinane" target="_blank">Kyle Kinane</a></td>
                </tr>
        
                <tr>
                    <td data-month="Nov">Nov 23 (Sa)<br><span class="time">07:00PM</span></td>
                    <td>Kyle Kinane Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/kyle-kinane-headlines-fat-man-istzrtspx"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/kyle-kinane" target="_blank">Kyle Kinane</a></td>
                </tr>
        
                <tr>
                    <td data-month="Nov">Nov 23 (Sa)<br><span class="time">10:00PM</span></td>
                    <td>Kyle Kinane Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/kyle-kinane-headlines-fat-man-nses8"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/kyle-kinane" target="_blank">Kyle Kinane</a></td>
                </tr>
        
                <tr>
                    <td data-month="Nov">Nov 24 (Su)<br><span class="time">08:00PM</span></td>
                    <td>Kyle Kinane Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/kyle-kinane-headlines-fat-man-nses8xocwc6n"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/kyle-kinane" target="_blank">Kyle Kinane</a></td>
                </tr>
        
                <tr>
                    <td data-month="Nov">Nov 25 (Mo)<br><span class="time">08:00PM</span></td>
                    <td>KILL TONY</td>
                    <td><a href="https://www.showclix.com/event/kill-tony-o8l-mothership-stlehgeoehje9wbwmjyqikayfmfofpn2"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Nov">Nov 29 (Fr)<br><span class="time">07:00PM</span></td>
                    <td>Owen Smith Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/owen-smith-headlines-fat-man-6llw"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/owen-smith" target="_blank">Owen Smith</a></td>
                </tr>
        
                <tr>
                    <td data-month="Nov">Nov 29 (Fr)<br><span class="time">10:00PM</span></td>
                    <td>Owen Smith Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/owen-smith-headlines-fat-man-6u"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/owen-smith" target="_blank">Owen Smith</a></td>
                </tr>
        
                <tr>
                    <td data-month="Nov">Nov 30 (Sa)<br><span class="time">07:00PM</span></td>
                    <td>Owen Smith Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/owen-smith-headlines-fat-man-8aj"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/owen-smith" target="_blank">Owen Smith</a></td>
                </tr>
        
                <tr>
                    <td data-month="Nov">Nov 30 (Sa)<br><span class="time">10:00PM</span></td>
                    <td>Owen Smith Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/owen-smith-headlines-fat-man-kay"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/owen-smith" target="_blank">Owen Smith</a></td>
                </tr>
        
                <tr>
                    <td data-month="Dec">Dec 01 (Su)<br><span class="time">08:00PM</span></td>
                    <td>Owen Smith Headlines Fat Man</td>
                    <td><a href="https://www.showclix.com/event/owen-smith-headlines-fat-man-yw"><span class="status-bullet status-available"></span>Available</a></td>
                    <td><a href="https://punchhub.ai/comedian/owen-smith" target="_blank">Owen Smith</a></td>
                </tr>
        
                <tr>
                    <td data-month="Dec">Dec 02 (Mo)<br><span class="time">08:00PM</span></td>
                    <td>KILL TONY</td>
                    <td><a href="https://www.showclix.com/event/kill-tony-o8l-mothership-stlehgeoehje9wbwmjyqikayfl4gutxd"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Dec">Dec 09 (Mo)<br><span class="time">08:00PM</span></td>
                    <td>KILL TONY</td>
                    <td><a href="https://www.showclix.com/event/kill-tony-o8l-mothership-stlehgeoehje9wbwmjyqikayfttniasf"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Dec">Dec 16 (Mo)<br><span class="time">08:00PM</span></td>
                    <td>KILL TONY</td>
                    <td><a href="https://www.showclix.com/event/kill-tony-o8l-mothership-stlehgeoehje9wbwmjyqikayfd8vdn9d"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td></td>
                </tr>
        
                <tr>
                    <td data-month="Dec">Dec 23 (Mo)<br><span class="time">08:00PM</span></td>
                    <td>KILL TONY</td>
                    <td><a href="https://www.showclix.com/event/kill-tony-o8l-mothership-stlehgeoehje9wbwmjyqikayfhufmdtu"><span class="status-bullet status-sold-out"></span>Sold Out</a></td>
                    <td></td>
                </tr>
        
            </tbody>
        </table>
        <script>
            $(document).ready(function() {
                var table = $('#showsTable').DataTable({
                    order: [[0, 'asc']],
                    paging: false,
                    info: false,
                    columnDefs: [
                        {
                            targets: 0,
                            type: 'date'
                        }
                    ]
                });

                // Create month filter
                var monthSelect = $('#monthFilter');
                monthSelect.empty().append('<option value="">All Months</option>');
                var months = [];
                $('td[data-month]').each(function() {
                    var month = $(this).data('month');
                    if (month && !months.includes(month)) {
                        months.push(month);
                    }
                });
                months.sort((a, b) => new Date(a + ' 1, 2000') - new Date(b + ' 1, 2000'));
                months.forEach(function(month) {
                    monthSelect.append('<option value="' + month + '">' + month + '</option>');
                });

                // Create status filter
                var statusSelect = $('#statusFilter');
                statusSelect.empty().append('<option value="">All Statuses</option>');
                statusSelect.append('<option value="Available">Available</option>');
                statusSelect.append('<option value="Sold Out">Sold Out</option>');

                // Apply filters
                $('#monthFilter, #statusFilter').on('change', function() {
                    var month = $('#monthFilter').val();
                    var status = $('#statusFilter').val();
                    table
                        .column(0).search(month ? month : '', true, false)
                        .column(2).search(status ? status : '', true, false)
                        .draw();
                });

                // Search icon functionality
                $('#searchIcon').click(function() {
                    $('#searchInput').toggle();
                    if ($('#searchInput').is(':visible')) {
                        $('#searchInput').focus();
                    }
                });

                $('#searchInput').on('keyup', function() {
                    table.search(this.value).draw();
                });
            });
        </script>
    </body>
    </html>
    `;

    return (
        <>
            <Helmet>
                <title>{Consts.ProjectName} Comedy Mothership</title>
                <meta name="description" content="Comedy Mothership" />
            </Helmet>
            <Header/>
            
            <Container fluid className="vh-100 d-flex flex-column justify-content-center align-items-center">
                <h4>Comedy Mothership</h4>
                <Frame
                    style={{
                        width: '100%', // Full width of parent container
                        maxWidth: '800px', // Optional: to limit the maximum width of the Frame
                        height: '80vh', // Adjust height as per your needs
                        border: 'none', // Remove borders if any
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' // Optional: Add a subtle shadow for better visibility
                    }}
                >
                    <div dangerouslySetInnerHTML={{ __html: customHtml }} />
                </Frame>
            </Container>

            <Footer/>
        </>
    );
}

export default ComedyMothership;