import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';

import "styles/theme.css";
import Views from './views';

const getYouTubeVideoId = (url) => {
    const prefix = "https://www.youtube.com/watch?v=";
    if (url.startsWith(prefix)) {
      return url.slice(prefix.length);
    }
    return url;
}

export const YouTubeEmbed = (props) => {
    const videoId = getYouTubeVideoId(props.url);
    const start = Math.floor(props.ts);

    return (
      <Container className="mt-4">
        <Row>
          <Col>
            <iframe width="368" height="207" src={`https://www.youtube.com/embed/${videoId}?start=${start}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            {props.views &&
              <Views views={props.views}/>
            }
          </Col>
        </Row>
      </Container>
  
    );
};

export default YouTubeEmbed;