import Footer from 'components/footer';
import Header from 'components/header';
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, InputGroup, Button, ButtonGroup } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import { Consts } from "consts";
import { Paths } from 'paths';
import getBackend from 'backend/backend';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import YouTubeEmbed from '../components/youtube';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchShortcut from 'components/search_shortcut';
import "styles/theme.css";


const JokeCard = (props) => {
    return (
      <Row>
      <Col md={12}>
        <Card className="mb-4">
          <Card.Header>{props.joke.title}</Card.Header>
          <Card.Body>
            <Row>
              <Col md={6}>
                <YouTubeEmbed url={props.joke.source_url} ts={props.joke.start} views={props.joke.view_count}/>
              </Col>
              <Col md={6}>
                <Card.Text>
                  <div dangerouslySetInnerHTML={{ __html: props.joke._formatted.text }} />
                </Card.Text>
                <Card.Text>
                  <strong>Genres:</strong> {props.joke.genres.join(", ")}
                </Card.Text>
                <Card.Text>
                  <strong>Topics:</strong> {props.joke.topics.join(", ")}
                </Card.Text>
                <ButtonGroup>
                  <Button className="mr-2" variant="primary" as={Link} to={Paths.Comedian.path + "/" + props.joke.comedian_id}>
                    View {props.joke.comedian_name}
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      </Row>
    );
};

const JokesSearchShortcuts = (props) => {
  return (
    <Row className="mt-4">
      <Col>
        <h5>Popular Joke Topics:</h5>
        <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'left' }}>
          <li>
            <SearchShortcut link="/jokes?q=Barack%2520Obama" text="Jokes about Barack Obama"/>
          </li>
          <li>
            <SearchShortcut link="/jokes?q=Avocado" text="Avocado humor"/>
          </li>
          <li>
            <SearchShortcut link="/jokes?q=Why%2520are%2520your%2520OnlyFans%2520videos%2520so%2520short%253F%2520I%25E2%2580%2599m%2520from%2520the%2520old%2520school%2520era%2520where%2520they%2520filmed%2520real%2520movies%21" text="Joke: Why are your OnlyFans videos so short?"/>
          </li>
          <li>
            <SearchShortcut link="/jokes?q=Procrastination" text="Procrastination jokes"/>
          </li>
          <li>
            <SearchShortcut link="/jokes?q=Texas" text="Texas-themed jokes"/>
          </li>
          </ul>
      </Col>
    </Row>
  )
};

export const Jokes = ({match}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const initialQuery = decodeURIComponent(queryParams.get('q') || '');

    const [jokes, setJokes] = useState([]);
    const [query, setQuery] = useState(initialQuery);

    const handleChange = async (e) => {
      e.preventDefault();

      const value = e.target.value;
      if (value) {
        queryParams.set('q', encodeURIComponent(value));
      } else {
        queryParams.delete('q');
      }

      navigate({
        pathname: location.pathname,
        search: `?${queryParams.toString()}`,
      });

      if (value.length === 0) {
        setJokes([]);
      }
      setQuery(value);
    }

    useEffect(() => {
      let canceled = false;

      const setJokesResult = (jokes) => {
        setJokes(jokes);
      }

      const searchJokes = async () => {
        let params = {order: ""};
        if (query.length === 0)
          return;

        const result = await getBackend().searchJoke(query, params);
        if (canceled)
          return;
  
        if (result.error == null) {
          setJokesResult(result.response.jokes);
        } else {
          window.location.href = Paths.NotFound.path;
        }
      };

      searchJokes();

      return async () => {
        canceled = true;
      }
    }, [query]);

    return (
        <div>
        <Header/>    
        <Container className={jokes.length === 0 ? "mt-2 vh-100" : "mt-2"}>
          <Row className="mb-4 justify-content-center">
              <Col xs={12} md={8} lg={6}>
                <Form className="d-flex">
                    <InputGroup>
                    <Form.Control
                        type="text"
                        placeholder="Search for Jokes by Content, Topics"
                        value={query}
                        onChange={(e) => handleChange(e)}
                        className="custom-input"
                    />
                    <InputGroup.Text>
                      <MagnifyingGlassIcon icon="search" style={{ width: '20px', height: '20px' }}/>
                    </InputGroup.Text>
                    </InputGroup>
                </Form>
                {query.length === 0 &&
                  <JokesSearchShortcuts/>
                }
              </Col>
          </Row>
          <Container>
            {jokes.map(u => <JokeCard key={u.id} joke={u}/>)}
          </Container>
        </Container>
        
        <Footer/>
      </div>
    );
}

const JokesPage = () => {
  return (
    <>
      <Helmet>
          <title>Jokes Explorer | {Consts.ProjectName}</title>
          <meta name="description" content="Comedian jokes explorer" />
      </Helmet>
      <Jokes/>
    </>
  );
}

export default JokesPage;