
import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Paths } from "paths";

import Home from "pages/home";
import Comedians from "pages/comedians";
import Comedian from "pages/comedian";
import NotFound from "pages/404";
import ServerError from "pages/500";
import Jokes from "pages/jokes";
import ReactGA from "react-ga4";
import AboutPage from 'pages/about';
import Clubs from 'pages/clubs';
import ComedyMothership from 'pages/comedymothership';
//TODO: import Videos from 'pages/videos';

const PageSwitch = () => {

  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search});
  }, [location]);

  return (
    <Routes>
      <Route exact path={Paths.NotFound.path} element={<NotFound/>} />
      <Route exact path={Paths.ServerError.path} element={<ServerError/>} />

      <Route exact path={Paths.Home.path} element={<Home/>} />
      <Route exact path={Paths.About.path} element={<AboutPage/>} />

      <Route exact path={Paths.Comedians.path} element={<Comedians/>} />
      <Route exact path={Paths.Comedian.path + "/:id"} element={<Comedian/>} />
      <Route exact path={Paths.Jokes.path} element={<Jokes/>} />
      <Route exact path={Paths.Clubs.path} element={<Clubs/>} />
      <Route exact path={Paths.ComedyMothership.path} element={<ComedyMothership/>} />

      {/* TODO: <Route exact path={Paths.Videos.path} element={<Videos/>} /> */}
      <Route path="*" element={<Navigate to="/404" replace />}/>
    </Routes>
  );

}

export default PageSwitch;