
import React from "react";
import { Container } from 'react-bootstrap';
import { Consts } from "consts";

import "styles/theme.css";

const Footer = (props) => {
  return (
    <footer className="footer mt-auto py-3">
        <Container>
        <span>&copy; 2024 {Consts.ProjectName} v{process.env.REACT_APP_VERSION}. All Rights Reserved.</span>
        </Container>
    </footer>
  );
};

export default Footer;