import React from 'react';
import { Helmet } from 'react-helmet';
import { Consts } from 'consts';

import "styles/theme.css";
import Comedians from './comedians';

const Home = () => {

    return (
        <>
            <Helmet>
                <title>Comedians Catalog | {Consts.ProjectName}</title>
                <meta name="description" content="Comedians catalog" />
            </Helmet>
            <Comedians/>
        </>
    );
}

export default Home;