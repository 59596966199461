import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import Header from 'components/header';
import Footer from 'components/footer';
import { Consts } from "consts";

const AboutPage = () => {
  return (
    <div>
        <Helmet>
            <title>About | {Consts.ProjectName}</title>
            <meta name="description" content={`About ${Consts.ProjectName}`} />
        </Helmet>
        <Header/>
        <Container className="my-5">
            <Row>
                <Col>
                <h2 className="mb-4">About {Consts.ProjectName}</h2>
                <p>
                Welcome to <strong>{Consts.ProjectName}</strong> – your ultimate stand-up comedy hub! Whether you’re just getting into stand-up or you’re a dedicated fan, {Consts.ProjectName} makes it super easy to discover comedians, learn about their style and jokes, catch upcoming shows, or watch their best performances.
                </p>

                <h3 className="mt-5 mb-3">What We Offer</h3>
                <ul>
                    <li>
                    <strong>Comedian Directory:</strong> Easily find comedians by genre, name, or location. Not sure who to watch? Our directory helps you get a feel for each comedian’s style so you can find the perfect match for your sense of humor.
                    </li>
                    <li>
                    <strong>Humor Insights:</strong> Search for jokes by genre, type, or comedian – so you’ll always have something to laugh at, no matter what you’re in the mood for.
                    </li>
                </ul>

                <h3 className="mt-5 mb-3">Our Mission</h3>
                <p>
                At <strong>{Consts.ProjectName}</strong>, we live and breathe comedy. Our mission is to create a space where fans can explore their favorite comedians and discover new talent. We’re here to bring the best laughs from across the globe straight to your screen.
                </p>

                <h3 className="mt-5 mb-3">Want to Help or Partner with Us?</h3>
                <p>
                If you’d like to help us improve the site or explore partnership opportunities, feel free to reach out to{' '}
                <a href="mailto:info@punchhub.ai">info@punchhub.ai</a>.
                </p>
                </Col>
            </Row>
        </Container>
        <Footer/>
    </div>
  );
};

export default AboutPage;