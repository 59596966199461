import Footer from 'components/footer';
import Header from 'components/header';
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Form, InputGroup} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import { Consts } from "consts";
import { Paths } from 'paths';
import { Link } from 'react-router-dom';
import getBackend from 'backend/backend';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { selectProfileImage } from './comedian';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchShortcut from 'components/search_shortcut';

import "styles/theme.css";

const ComedianCard = (props) => {
    const comedian = props.comedian;

    const shortenString = (str, maxLength) => {
      if (str.length <= maxLength) {
          return str;
      }
      return str.substring(0, maxLength - 3) + "...";
    }

    const image = selectProfileImage(comedian);

    return (
      <Row>
      <Col md={12}>
        <Card className="mb-4">
          <Card.Header>{comedian.name}</Card.Header>
          <Card.Body>
          <Row>
              <Col md={4}>
                {image &&
                  <Card.Img variant="top" src={image.image.url} style={{ width: '' + 100 + '%', height: 'auto' }} lt="profile image" />
                }
              </Col>
              <Col md={8}>
                {comedian.burn && !comedian.burn.includes("?") &&
                  <Card.Text><strong>Born:</strong> {comedian.burn}</Card.Text>
                }
                {comedian.current_city &&
                  <Card.Text><strong>City:</strong> {comedian.current_city}, {comedian.current_state}, {comedian.current_country}</Card.Text>
                }
                {comedian.genres.length > 0 &&
                  <Card.Text><strong>Genres:</strong> {comedian.genres.join(", ")}</Card.Text>
                }
                {comedian.topics.length > 0 &&
                  <Card.Text><strong>Topics:</strong> {comedian.topics.join(", ")}</Card.Text>
                }
                {comedian.awards.length > 0 &&
                  <Card.Text><strong>Awards:</strong> {comedian.awards.join(", ")}</Card.Text>
                }
                {comedian.hobbies.length > 0 &&
                  <Card.Text><strong>Hobbies:</strong> {comedian.hobbies.join(", ")}</Card.Text>
                }
                <Card.Text>
                  {shortenString(comedian.bio, 400)}
                </Card.Text>
                <Button variant="primary" as={Link} to={Paths.Comedian.path + "/" + comedian.id}>Read more</Button>

              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      </Row>
    );
};


const ComedianSearchShortcuts = (props) => {
  return (
    <Row className="mt-4">
      <Col>
        <h5>Discover Popular Comedians:</h5>
        <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'left' }}>
          <li>
            <SearchShortcut link="/comedians?q=Joe%2520Rogan" text="Joe Rogan"/>
          </li>
          <li>
            <SearchShortcut link="/comedians?q=Louis%2520C.K." text="Louis C.K."/>
          </li>
          <li>
            <SearchShortcut link="/comedians?q=Taylor%2520Tomlinson" text="Taylor Tomlinson"/>
          </li>
          <li>
            <SearchShortcut link="/comedians?q=Michelle%2520Wolf" text="Michelle Wolf"/>
          </li>
          <li>
            <SearchShortcut link="/comedians?q=George%2520Carlin" text="George Carlin"/>
          </li>
          <li>
            <SearchShortcut link="/comedians?q=Whitney%2520Cummings" text="Whitney Cummings"/>
          </li>
          </ul>
      </Col>
      <Col>
        <h5>Explore by Themes:</h5>
        <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'left' }}>
          <li>
            <SearchShortcut link="/comedians?q=Los%2520Angeles" text="Comedians from Los Angeles"/>
          </li>
          <li>
            <SearchShortcut link="/comedians?q=Parenting" text="Comedians known for parenting jokes"/>
          </li>
          </ul>
      </Col>
    </Row>
  )
};

export const Catalog = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const initialQuery = decodeURIComponent(queryParams.get('q') || '');

    const [comedians, setCommedians] = useState([]);
    const [query, setQuery] = useState(initialQuery);

    const handleChange = async (e) => {
      e.preventDefault();

      const value = e.target.value;
      if (value) {
        queryParams.set('q', encodeURIComponent(value));
      } else {
        queryParams.delete('q');
      }

      navigate({
        pathname: location.pathname,
        search: `?${queryParams.toString()}`,
      });

      if (value.length === 0) {
        setCommedians([]);
      }
      setQuery(value);
    }

    useEffect(() => {
      let canceled = false;

      const setComediansResult = (comedians) => {
        setCommedians(comedians);
      }

      const searchComedians = async () => {
        if (query.length === 0)
          return;

        const result = await getBackend().searchComedian(query);
        if (canceled)
          return;
  
        if (result.error == null) {
          setComediansResult(result.response.comedians);
        } else {
          window.location.href = Paths.NotFound.path;
        }
      };

      searchComedians();

      return async () => {
        canceled = true;
      }
    }, [query]);

    return (
        <div>
        <Header/>    
        <Container className={comedians.length === 0 ? "mt-2 vh-100" : "mt-2"}>
          <Row className="mb-4 justify-content-center">
              <Col xs={12} md={8} lg={6}>
                <Form className="d-flex">
                    <InputGroup>
                    <Form.Control
                        type="text"
                        placeholder="Search for Comedians"
                        value={query}
                        onChange={(e) => handleChange(e)}
                        className="custom-input"
                    />
                    <InputGroup.Text>
                      <MagnifyingGlassIcon icon="search" style={{ width: '20px', height: '20px' }}/>
                    </InputGroup.Text>
                    </InputGroup>
                </Form>
                {query.length === 0 &&
                  <ComedianSearchShortcuts/>
                }
              </Col>
          </Row>

          <Container>
            {comedians.map(u => <ComedianCard key={u.id} id={u.id} comedian={u} />)}
          </Container>
        </Container>
        
        <Footer/>
      </div>
    );
}

const CatalogPage = () => {
  return (
    <>
      <Helmet>
          <title>Comedians Catalog | {Consts.ProjectName}</title>
          <meta name="description" content="Comedians catalog" />
      </Helmet>
      <Catalog/>
    </>
  );
}

export default CatalogPage;