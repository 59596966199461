import React from 'react';
import { Helmet } from 'react-helmet';
import { Consts } from 'consts';

import "styles/theme.css";
import Header from 'components/header';
import Footer from 'components/footer';
import { Container, Row, Col } from 'react-bootstrap';

const Clubs = () => {
    return (
        <>
            <Helmet>
                <title>Comedian Clubs | {Consts.ProjectName}</title>
                <meta name="description" content="Comedian clubs" />
            </Helmet>
            <Header/>

            <Container className="vh-100 d-flex justify-content-center">
                <Row>
                    <Col>
                        <h4>Featured Venue</h4>
                        <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'left' }}>
                            <li>
                                <a href="/clubs/comedymothership" style={{ textDecoration: 'underline' }}>Comedy Mothership, Austin, TX</a>
                            </li>                            
                        </ul>

                    </Col>
                </Row>
            </Container>

            <Footer/>
        </>
    );
}

export default Clubs;