import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import App from './app';
import "styles/theme.css";
import Helmet from 'react-helmet';
import { BrowserRouter } from 'react-router-dom';

import { createRoot } from 'react-dom/client';
import { Consts } from 'consts';

import ReactGA from "react-ga4";

ReactGA.initialize(Consts.ProjectGoogleAnalytics);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <>
      <Helmet>
        <title>{Consts.ProjectName}</title>
        <meta name="description" content={Consts.ProjectContent} />
      </Helmet>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </>
);